<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center" id="formWrapper">
        <v-col cols="12" sm="8" md="4">
          <img
            :src="logoUrl"
            id="logo"
            alt="Logo"
            width="150px"
            height="160px"
            max-height="160px"
          />
          <v-card id="loginCard">
            <v-card-text>
              <v-form
                name="loginform"
                @submit.prevent="login"
                v-model="formValid"
              >
                <v-text-field
                  label="Email"
                  name="email"
                  prepend-icon="mdi-account"
                  type="text"
                  v-model="email"
                  :rules="emailRules"
                />

                <v-text-field
                  id="password"
                  label="Password"
                  name="password"
                  prepend-icon="mdi-lock"
                  :type="passwordVisible"
                  autocomplete="new-password"
                  v-model="password"
                  :rules="requiredRule"
                  :append-icon="passwordIcon"
                  @click:append="showPassword"
                  @keyup.enter="login"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-fade-transition>
                <v-btn
                  outlined
                  color="secondary"
                  href="https://console.anewo.at/pwreset"
                  >Passwort vergessen</v-btn
                >
              </v-fade-transition>
              <v-spacer />
              <v-btn
                color="primary"
                @click="login"
                name="submit"
                type="submit"
                :disabled="!formValid"
                >Login</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    email: "",
    password: "",
    passwordVisible: "password",
    passwordIcon: "mdi-eye-outline",
    formValid: false,
    requiredRule: [(value) => !!value || "Dieses Feld wird benötigt!"],
    emailRules: [
      (v) => !!v || "E-mail wird benötigt!",
      (v) =>
        /.+@.+/.test(v) ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) ||
        "E-mail Adresse ungültig",
    ],
  }),

  computed: {
    logoUrl() {
      return `${process.env.VUE_APP_API_URL}/ressources/images/logo.png`;
    },
  },
  methods: {
    showPassword() {
      if (this.passwordVisible === "password") {
        this.passwordVisible = "text";
        this.passwordIcon = "mdi-eye-off-outline";
      } else {
        this.passwordVisible = "password";
        this.passwordIcon = "mdi-eye-outline";
      }
    },
    login() {
      if (!this.formValid) {
        this.$notify({
          group: "dataline",
          type: "error",
          title: "Logingehler",
          text: "Logindaten unvollständig",
        });
        return false;
      }
      this.$store
        .dispatch("user/login", {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.$router.push("/home");
        })
        .catch(() => {
          /*const msg =
            error.response && error.response.data
              ? error.response.data.error
              : error.message;*/
          this.$notify({
            group: "dataline",
            type: "error",
            title: "Logingehler",
            text: "Benutzername oder Passwort nicht korrekt",
          });
        });
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped>
#formWrapper {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px 0px 100px 0px;
  margin-top: 100px;
  transform: skewY(-2deg);
  text-align: center;
}
#loginCard {
  transform: skewY(2deg);
}

#logo {
  width: 150px;
  margin-top: -110px;
  margin-bottom: 50px;
}
</style>
